import { Component } from "@angular/core";
import { AuthService } from '../auth/auth.service'

@Component({
    selector: "news-page",
    templateUrl: "newsPage.component.html",
    styleUrls: []
})
export class NewsPage {
    constructor(
        public authService: AuthService) { }
}