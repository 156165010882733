<mat-toolbar class="menu-top">
    <mat-toolbar-row>
        <div class="col-xs-1"></div>
        <div class="menu-container">
            <div class="nav-item">
                <a class="nav-link" routerLink="/www" routerLinkActive="is-active" style="cursor: pointer;">www</a>
            </div>
            <div class="nav-item">
                <a class="nav-link" routerLink="/manager" routerLinkActive="is-active"
                    style="cursor: pointer;">manager</a>
            </div>
            <div *ngIf="authService.isLoggedOut()" class="nav-item">
                <a class="nav-link" routerLink="/login" routerLinkActive="is-active">
                    <img class="lock" src="assets/images/icons/lock.png" alt="Log in">
                </a>
            </div>
            <div *ngIf="authService.isLoggedIn()" class="nav-item">
                <a class="nav-link" (click)="onLogout()" style="cursor: pointer;">wyloguj</a>
            </div>
        </div>
        <div class="col-xs-1"></div>
    </mat-toolbar-row>
</mat-toolbar>