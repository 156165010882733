import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <== add the imports!
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthGuardService, AccessGuard, AuthService, AuthInterceptor } from './auth/auth.service';

import { APIInterceptor } from './auth/auth.service';

//Content
import { HomeComponent } from './home/home.component'
import { AboutComponent } from './about/about.component'
import { ContactComponent } from './contact/contact.component'
import { NavigationComponent } from './navigation2/navigation.component'
import { FooterComponent } from './footer/footer.component'
import { LoginComponent } from './auth/login/login.component'
import { OfferComponent } from './offer/offer.component';
import { Angular2ImageGalleryModule } from './angular2-image-gallery/angular2-image-gallery.module';
import { Gallery } from './gallery/gallery.component';
import { GalleryPage } from './gallery/galleryPage.component';
import { AlbumComponent } from './gallery/album.component';
import { DataService } from './gallery/dataService';
import { SortPipe } from "./gallery/dateSortPipe";

import { NewsPage } from './news/newsPage.component';
import { NewsList } from './news/newsList.component';
import { NewsNewItem } from './news/newsNewItem.component';
import { NewsService } from './news/newsService';

import { InfoItem } from './info/info.component';

import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    NavigationComponent,
    FooterComponent,
    LoginComponent,
    OfferComponent,
    SortPipe,
    Gallery,
    GalleryPage,
    AlbumComponent,
    NewsPage,
    NewsList,
    NewsNewItem,
    InfoItem
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    Angular2ImageGalleryModule
  ],
  providers: [
    AccessGuard,
    AuthService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DataService,
    NewsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
