import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccessGuard } from './auth/auth.service';

// Content
import { HomeComponent } from './home/home.component'
import { AboutComponent } from './about/about.component'
import { ContactComponent } from './contact/contact.component'
import { LoginComponent } from './auth/login/login.component'
import { OfferComponent } from './offer/offer.component';
import { GalleryPage } from './gallery/galleryPage.component';
import { AlbumComponent } from './gallery/album.component';
import { NewsPage } from './news/newsPage.component';

import { Kreischberg012023 } from './travel/kreischberg-01-2023/kreischberg-01-2023.component'
import { Soelden112022 } from './travel/soelden-11-2022/soelden-11-2022.component'
import { Zwardon032023 } from './travel/zwardon-03-2023/zwardon-03-2023.component';
import { Lyngen032023 } from './travel/lyngen-03-2023/lyngen-03-2023.component';
import { Kaprun042023 } from './travel/kaprun-04-2023/kaprun-04-2023.component';

const baseTitle: string = " - Jump Camp"

const routes: Routes = [
  {
    path: '',
    redirectTo: 'manager',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    title: 'Cześć' + baseTitle
  },
  {
    path: 'about',
    component: AboutComponent,
    title: 'O nas' + baseTitle
  },
  {
    path: 'contact',
    component: ContactComponent,
    title: 'Kontakt' + baseTitle
  },
  {
    path: 'gallery',
    component: GalleryPage,
    title: 'Galeria' + baseTitle
  },
  {
    path: 'news',
    component: NewsPage,
    title: 'Aktualności' + baseTitle
  },
  {
    path: 'gallery/album/:id',
    component: AlbumComponent,
    title: 'Galeria' + baseTitle
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login' + baseTitle
  },
  {
    path: 'offer',
    component: OfferComponent,
    title: 'Oferta' + baseTitle
  },
  // {
  //   path: "travel",
  //   component: Kreischberg012023,
  //   title: 'Kreischberg 01.2023' + baseTitle
  // },
  {
    path: "travel/kreischberg-01-2023",
    component: Kreischberg012023,
    title: 'Kreischberg 01.2023' + baseTitle
  },
  {
    path: "travel/soelden-11-2022",
    component: Soelden112022,
    title: 'Soelden 11.2022' + baseTitle
  },
  {
    path: "travel/zwardon-03-2023",
    component: Zwardon032023,
    title: 'Zwardoń 03.2023' + baseTitle
  },
  {
    path: "travel/lyngen-03-2023",
    component: Lyngen032023,
    title: 'Lyngen 03.2023' + baseTitle
  },
  {
    path: "travel/kaprun-04-2023",
    component: Kaprun042023,
    title: 'Kaprun 04.2023' + baseTitle
  },
  {
    path: 'manager',
    loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
    canLoad: [AccessGuard],
    canActivate: [AccessGuard]
  },
  {
    path: 'www',
    loadChildren: () => import('./www/www.module').then(m => m.WwwModule),
    canLoad: [AccessGuard],
    canActivate: [AccessGuard]
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
