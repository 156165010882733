import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DataService } from "./dataService";
import { Album } from "./album";
import { HttpRequest, HttpEventType, HttpClient } from "@angular/common/http";

export interface Food {
    value: string;
    viewValue: string;
}

@Component({
    selector: "the-gallery",
    templateUrl: "gallery.component.html",
    styleUrls: ['./gallery.component.css']
})

export class Gallery implements OnInit {
    public editMode: boolean;
    public albums: Array<Album> = new Array<Album>();
    private message: string;
    public random: string = ''; //used to refresh images after choosing new ones

    trackArray(index: number, album: { folderName: any }) {
        return album.folderName;
    }

    // trackArray(index: string, album: Album) {
    //     return album.folderName;
    // }

    private compare(item, otherArray): boolean {
        return otherArray.filter(function (other) {
            return (JSON.stringify(item) === JSON.stringify(other));
        }).length == 0;
    }


    constructor(
        private router: Router,
        private authService: AuthService,
        private data: DataService, private http: HttpClient, private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.data.loadAlbums()
            .subscribe(success => {
                if (success) {
                    this.albums = this.data.albums.map(x => Object.assign({}, x));

                }
            });
    }

    adminRole() {
        if (this.authService.isLoggedIn()) {
            //TODO: Add checking user role
            return true;
        }
        return false;
    }

    openAlbum(folderName) {
        this.router.navigate(['/gallery/album', folderName]);
    }

    onEdit() {

        this.editMode = true;
    }

    onCancel() {
        this.albums = this.data.albums.map(x => Object.assign({}, x));
        this.editMode = false;
    }

    onAddNew() {
        let album = new Album();
        let ids = this.albums.map(a => a.id);
        var id = Math.max(...ids) + 1;
        album.id = id;
        this.albums.unshift(album);
    }

    onUpdate() {
        this.editMode = false;
        //Find differences (changes made by user)

        let modifiedAlbums = this.albums.filter(album => {
            if (this.isAlbumNew(album.id)) {
                return true;
            }
            return this.compare(album, this.data.albums);
        });
        modifiedAlbums.forEach((album) => {
            this.data.updateAlbum(album).subscribe(value => {
                if (value) {
                    this.albums = this.data.albums.map(x => Object.assign({}, x));
                    alert("Changes saved :)");
                }
                else {
                    this.onCancel();
                    alert("Something went wrong :(");
                    this.message = "Something went wrong :(";
                }
            });
        });
    }

    private isAlbumNew(id: number): boolean {
        return this.data.albums.filter(album => album.id == id).length == 0;
    }

    onDelete(id) {
        if (this.isAlbumNew(id)) {
            if (confirm("Are you sure to delete newly created album?")) {
                var album = this.albums.filter(a => a.id == id)[0];
                var index = this.albums.indexOf(album);
                this.albums.splice(index, 1);
            }
        }
        else {
            if (confirm("Are you sure to delete permanently?")) {
                this.data.deleteAlbum(id).subscribe(value => {
                    if (value) {
                        this.albums = this.albums.filter(function (obj) {
                            return obj.id !== id;
                        });
                    }
                    else {
                        this.message = "Something went wrong :(";
                    }
                });
            }
        }
    }

    //TODO File verification - only images allowed
    upload(files, folderName) {
        if (files.length === 0)
            return;

        const formData = new FormData();
        console.log(files);
        let file = files[0];
        formData.append(file.name, file);
        formData.append('folderName', 'gallery/' + folderName);
        formData.append('fileName', "0.jpg");
        const uploadReq = new HttpRequest('POST', window.location.origin + `/api/upload`, formData, {
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) { }
            else if (event.type === HttpEventType.Response) {
                this.random += '?random+\=' + Math.random();
                this.cd.detectChanges();
                this.message = event.body.toString();
            }
        });
    }

}