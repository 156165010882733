import { Component, OnInit } from '@angular/core';
import { AuthService, LoginModel } from '../auth.service';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public errorMessage: string = "";
    private redirectUrl: string | null = null;

    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        if (authService.isLoggedIn()) {
            router.navigate([""])
        }
    }

    public creds: LoginModel = {
        username: "",
        password: "",
        rememberMe: false
    };

    onLogin() {
        this.authService.login(this.creds)
            .subscribe(success => {
                if (success) {
                    console.warn(this.redirectUrl)
                    this.router.navigate([this.redirectUrl || ""]);
                } else {
                    this.router.navigate(["/"])
                }

            }, err => this.errorMessage = "Failed to login")
    }


    ngOnInit() {
        // Capture the redirect URL from the query parameters
        this.activatedRoute.queryParams.subscribe(params => {
            this.redirectUrl = params['redirect'] || null;
        });
    }
}
